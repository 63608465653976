<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Modulo de Carta - Listar</strong>
          </CCardHeader>
          <CCardBody>
            <b-row>
              <b-col sm="12" md="7"></b-col>
               <b-col sm="12" md="1">
                  <!-- <b-link v-if="Permission('LetterAdd')" class="btn form-control btn-warning" :to="{ path: '/Lettero/importar' }" title="Importar Letteros" append><i class="fas fa-file-upload"></i></b-link> -->
              </b-col>

              <b-col sm="12" md="1">
                  <b-button type="button" title="Exportar Excel" @click="ExportExcel" class="form-control" variant="success"><i class="fas fa-file-excel"></i></b-button>
              </b-col>
              <b-col sm="6" md="2">
                <b-input-group>
                  <b-form-input  v-model="search" class="form-control"></b-form-input>
                  <b-input-group-append>
                    <b-button variant="primary" @click="ListLetter"><b-icon icon="search"></b-icon></b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-col>
              <b-col sm="6" md="1">
                <b-link v-if="Permission('LetterAdd')" class="btn form-control btn-primary" :to="{ path: '/carta/nuevo' }" append><i class="fas fa-file"></i></b-link>
              </b-col>
            </b-row>
        
            <div class="table-responsive mt-3 height-table">
              <table class="table table-hover table-bordered">
                <thead>
                  <tr>
                    <th width="5%" class="text-center">#</th>
                    
                    <th width="6%" class="text-center">Código</th>
                    <th width="60%" class="text-center">Nombre</th>
                    <th width="10%" class="text-center">Categoria</th>
                    <th width="8%" class="text-center">P. Venta</th>
                    <th width="6%" class="text-center">Estado</th>
                    <th width="8%" class="text-center">Acciones</th>
                  </tr>
                </thead>
                <tbody v-for="(item, it) in data_table" :key="it">
                  <tr>
                    <td class="text-center">{{ it + 1 }}</td>
                    <td class="text-center"> {{ item.code }}</td>
                    <td class="text-left"> {{ item.name + (item.presentation.length == 0 ? "": " - "+item.presentation) }}</td>
                    <td class="text-left"> {{ item.category_name }}</td>
                    <td class="text-right"> {{ item.sale_price }}</td>
                    <td class="text-center">
                      <b-badge v-if="item.state == 1" variant="success">Activo</b-badge>
                      <b-badge v-if="item.state == 0" variant="danger">Anulado</b-badge>
                    </td>
                    <td class="text-center">
                      <b-dropdown bloque size="sm" text="Acciones" right>
                        <b-dropdown-item v-if="Permission('LetterEdit')" @click="EditLetter(item.id_letter)">Editar</b-dropdown-item>
                        <b-dropdown-item v-if="Permission('LetterView')" @click="ViewLetter(item.id_letter)">Ver</b-dropdown-item>
                        <b-dropdown-item v-if="Permission('LetterDelete')" @click="ConfirmDeleteLetter(item.id_letter)">Eliminar</b-dropdown-item>
                      </b-dropdown>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <b-row class="mt-4">
              <b-col md="8">
                <b-pagination
                  v-model="currentPage"
                  v-on:input="ListLetter"
                  :total-rows="rows"
                  :per-page="perPage"
                  align="center"
                ></b-pagination>
              </b-col>
              <b-col md="4 text-center">
                <p>Pagina Actual: {{ currentPage }}</p>
              </b-col>
            </b-row>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
import { mapState } from "vuex";

export default {
  name: "UsuarioList",
  data() {
    return {
      module:'Letter',
      perPage: 15,
      currentPage: 1,
      rows: 0,
      search: "",
      data_table: [],
    };
  },
  mounted() {
    this.ListLetter();
  },
  methods: {
    ListLetter,
    EditLetter,
    ViewLetter,
    ConfirmDeleteLetter,
    DeleteLetter,
    Permission,
    ExportExcel,
  },

  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
  },
};

//listar usuario
function ListLetter() {
  let search = this.search == "" ? "all" : this.search;
  let me = this;
  let url = this.url_base + "letter/list/" + search + "?page=" + this.currentPage;
  axios({
    method: "GET",
    url: url,
    headers: {token: this.token, module:this.module,role: 1},
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.rows = response.data.result.total;
        me.data_table = response.data.result.data;
      } else {
        Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    });
}

function EditLetter(id_letter) {
  this.$router.push({
    name: "LetterEdit",
    params: { id_letter: je.encrypt(id_letter) },
  });
}

function ViewLetter(id_letter) {
  this.$router.push({
    name: "LetterView",
    params: { id_letter: je.encrypt(id_letter) },
  });
}

function ExportExcel() {  
  let url = this.url_base + "excel-letters";
  window.open(url,'_blank');
}

// Confirmar eliminar
function ConfirmDeleteLetter(id_letter) {
  Swal.fire({
    title: "Esta seguro de eliminar el Lettero?",
    text: "No podrás revertir esto!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Si, Estoy de acuerdo!",
  }).then((result) => {
    if (result.value) {
      this.DeleteLetter(id_letter);
    }
  });
}

// eliminar usuario
function DeleteLetter(id_letter) {
  let me = this;
  let url = this.url_base + "letter/delete/" + id_letter;
  axios({
    method: "delete",
    url: url,
    headers: {
      token: this.token,
      module: this.module,
      role: 4,
    },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        const index = me.data_table.map(item => item.id_letter).indexOf(response.data.result.id_letter);
        me.data_table.splice(index, 1);
        Swal.fire({ icon: 'success', text: response.data.message, timer: 3000,})
      } else {
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    });
}

// permisos
function Permission(module_permission) {
  let user_permissions = window.localStorage.getItem("user_permissions");
  user_permissions = JSON.parse(JSON.parse(je.decrypt(user_permissions)));
  if (user_permissions.indexOf(module_permission) > -1) {
    return true;
  } else {
    return false;
  }
}
</script>
